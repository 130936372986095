import React, { useState, useEffect, useContext } from "react"
import axios from "axios"

import Seo from "../components/seo"
import { graphql } from "gatsby"
import { addDoc, collection } from "firebase/firestore"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { db, storage } from "../firebase/config"
// components
import { useAuthContext } from "../hooks/useAuthContext"
import usePage from "../hooks/usePage"
import { MessagingConfigurationContext } from "twilio/lib/rest/verify/v2/service/messagingConfiguration"

const IndexPage = props => {
  const colRef = collection(db, "property")

  // Information useState
  const [listingTitle, setListingTitle] = useState("")
  const [placedBy, setPlacedBy] = useState("")
  const [propertyTypeSubcategory, setPropertyTypeSubcategory] = useState({})
  const [propertyType, setPropertyType] = useState({})
  const [description, setDescription] = useState("")
  const [featuredImage, setFeaturedImage] = useState(null)
  const [galleryImages, setGalleryImages] = useState([])
  const [featuredImagePath, setFeaturedImagePath] = useState(null)
  const [galleryImagesPaths, setGalleryImagesPaths] = useState([])

  // location useState
  const [country, setCountry] = useState({})
  const [district, setDistrict] = useState({})
  const [caza, setCaza] = useState({})
  const [town, setTown] = useState("")
  const [street, setStreet] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [address, setAddress] = useState("")
  const [building, setBuilding] = useState("")
  const [longitude, setLongitude] = useState("")
  const [latitude, setLatitude] = useState("")

  // details useState
  const [numBedrooms, setNumBedrooms] = useState("0")
  const [numBathrooms, setNumBathrooms] = useState("0")
  const [numSalons, setNumSalons] = useState("0")
  const [numDiningRooms, setNumDiningRooms] = useState("0")
  const [numLivingRoom, setNumLivingRooms] = useState("0")
  const [numBalcony, setNumBalcony] = useState("0")
  const [numFloors, setNumFloors] = useState("0")
  const [apartmentsPerFloor, setApartmentsPerFloor] = useState("0")
  const [area, setArea] = useState("")
  const [legalStatus, setLegalStatus] = useState("")
  const [paymentMethod, setPaymentMethod] = useState({})
  const [otherPaymentMethod, setOtherPaymentMethod] = useState("")

  // financials useState
  const [afterRepairValue, setAfterRepairValue] = useState("")
  const [saleOrRent, setSaleOrRent] = useState({})
  const [rentPrice, setRentPrice] = useState("")
  const [salePrice, setSalePrice] = useState("")
  const [currentRent, setCurrentRent] = useState("")
  const [builtTax, setBuiltTax] = useState("")
  const [insurance, setInsurance] = useState({})
  const [propertyManagement, setPropertyManagement] = useState({})
  const [grossCashflow, setGrossCashFlow] = useState("")
  const [capRate, setCapRate] = useState("")

  // amenities
  const [addedAmenities, setAddedAmenities] = useState([])
  const [checkedAmenities, setCheckedAmenities] = useState([])

  // to check if drafts exists
  const [sortedDocs, setSortedDocs] = useState([])
  const [createNewListingState, setCreateNewListingState] = useState(false)

  const { user, authIsReady } = useAuthContext()
  const [loading, setLoading] = useState(false)
  const [pageWidgets, setPageWidgets] = usePage("home")
  const [userId, setUserId] = useState(" ")

  const [count, setCount] = useState(81)
  const [data, setData] = useState([])
  const [imgUrl, setImgUrl] = useState("")

  const [imageUrl, setImageUrl] = useState("")
  const [imgGalerie, setImgGalerie] = useState([])

  useEffect(() => {
    setLoading(false)
  }, [pageWidgets])

  const uploadThumbnailProcess = () => {
    return new Promise(resolve => {
      if (imgUrl) {
        const reference = ref(storage, featuredImagePath)
        uploadBytes(reference, imgUrl).then(() => {
          getDownloadURL(reference).then(url => {
            resolve({ url: url, reference: featuredImagePath })
          })
        })
      } else {
        resolve({ url: featuredImage, reference: featuredImagePath })
      }
    })
  }

  const resetValues = () => {
    setListingTitle("")
    setPlacedBy("")
    setPropertyTypeSubcategory({})
    setPropertyType({})
    setDescription("")
    setFeaturedImage(null)
    setGalleryImages([])
    setFeaturedImagePath(null)
    setGalleryImagesPaths([])
    setCountry({})
    setDistrict({})
    setCaza({})
    setTown("")
    setStreet("")
    setZipCode("")
    setAddress("")
    setBuilding("")
    setLongitude("")
    setLatitude("")
    setNumBedrooms("0")
    setNumBathrooms("0")
    setNumSalons("0")
    setNumDiningRooms("0")
    setNumLivingRooms("0")
    setNumBalcony("0")
    setNumFloors("0")
    setApartmentsPerFloor("0")
    setArea("")
    setLegalStatus("")
    setPaymentMethod({})
    setOtherPaymentMethod("")
    setAfterRepairValue("")
    setSaleOrRent({})
    setRentPrice("")
    setSalePrice("")
    setCurrentRent("")
    setBuiltTax("")
    setInsurance("")
    setPropertyManagement("")
    setGrossCashFlow("")
    setCapRate("")
    setAddedAmenities([])
    setCheckedAmenities([])
  }
  const retrieve = () => {
    axios
      .get(
        `https://onlinepropertydeals.com/wp-json/migration/v1/properties?page=3&pp=100`
      )
      .then(res => {
        const persons = res.data
        setData(persons)
      })
      .then(res => {})
  }

  let singledata

  const getImg = async () => {
    const response = await fetch(imageUrl)
    const imageBlob = await response.blob()
    const reader = new FileReader()
    reader.readAsDataURL(imageBlob)
    reader.onloadend = () => {
      const base64data = reader.result
      setImgUrl(base64data)
    }
  }

  const galeryImg = galery => {
    const tabel = galery.map(type => {
      return { reference: type, url: type }
    })
    return tabel
  }

  const workData = () => {
    singledata = data.properties[count]
    setListingTitle(singledata.title)
    setPropertyType({
      label: singledata.property_categories[0],
      value: singledata.property_categories[0],
    })
    setPropertyTypeSubcategory({
      label: singledata.categories[0],
      value: singledata.categories[0],
    })
    setDescription(singledata.description.replace(/(<([^>]+)>)/gi, ""))
    setCountry({ label: singledata.country, value: singledata.country })
    setTown(singledata.city)
    setZipCode(singledata.postcode)
    setAddress(singledata.address)
    setBuilding(singledata.building_name)
    setLongitude(singledata.longitude)
    setLatitude(singledata.latitude)
    setNumBedrooms(singledata.bedrooms)
    setNumBathrooms(singledata.bathrooms)
    setNumBalcony(singledata.balconies)
    setNumFloors(singledata.floor)
    setRentPrice(singledata.sale_or_rent_price)
    setApartmentsPerFloor(singledata.apartments_per_floor)
    setArea(singledata.area)
    setPaymentMethod({
      label: singledata.payment_terms,
      value: singledata.payment_terms,
    })
    setAfterRepairValue(singledata.area_postfix_text)
    setSaleOrRent({
      label: singledata.property_status,
      value: singledata.property_status,
    })
    setUserId(singledata.user_id)
    setImgGalerie(galeryImg(singledata.gallery_images))

    //image
    setImageUrl({ reference: singledata.image, url: singledata.image })

    return 0
  }

  const migration = () => {
    let thumbnailUpload, galleryUpload

    // uploadThumbnailProcess().then(value => {
    //   thumbnailUpload = value

    addDoc(colRef, {
      title: listingTitle,
      type: propertyType,
      type_subcategory: propertyTypeSubcategory,
      description: description,
      added_by: userId,
      featured_image: imageUrl,
      gallery: imgGalerie,
      country: country,
      district: "",
      caza: "",
      town: town,
      street_name: "",
      zip_code: zipCode,
      address: address,
      building: building,
      longitude: longitude,
      latitude: latitude,
      num_bedrooms: numBedrooms,
      num_bathrooms: numBathrooms,
      num_balcony: numBalcony,
      num_salons: "",
      num_living_room: "",
      num_dining_rooms: "",
      num_floors: numFloors,
      rent_price: rentPrice,
      apartments_per_floor: apartmentsPerFloor,
      area: area,
      legal_status: "",
      payment_method: paymentMethod,
      other_payment_method: "",
      after_repair_value: afterRepairValue,
      rent_or_sale: saleOrRent,
      sale_price: rentPrice,
      current_rent: rentPrice,
      built_tax: "",

      property_management: "",
      cap_rate: "",
      insurance: { label: "", value: "" },
      gross_cashflow: "",
      checkedAmenities: [{ title: "", value: "" }],
      addedAmenities: [{ title: "", value: "" }],
    }).then(() => {
      setCount(count + 1)
    })

    // })
    // })
  }

  return (
    <>
      {authIsReady && (
        <>
          <>
            <Seo title="migration" />
            <div>
              <button onClick={retrieve}>get data</button>
              <button onClick={workData}>testing</button>
              <button onClick={migration}>migrate</button>
            </div>
          </>
        </>
      )}
    </>
  )
}
export const query = graphql`
  {
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
    allNews(limit: 3, sort: { fields: created_at }) {
      edges {
        node {
          description
          featured_image
          id
          overview
          title
          topic_title
          topic
          created_at
          localImage {
            childImageSharp {
              fixed(width: 300, height: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    allEvents(limit: 2, sort: { fields: date }) {
      edges {
        node {
          date
          city
          address
          name
          id
          country
          thumbnail {
            url
          }
        }
      }
    }
    allPage(filter: { name: { eq: "home" } }) {
      edges {
        node {
          name
          title
          id
          description
          widgets {
            name
            type
          }
        }
      }
    }
    allHero {
      edges {
        node {
          image {
            url
          }
          localImage {
            childImageSharp {
              fixed(width: 2000, height: 800) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          id
          title
          title_ar
          paragraph
          paragraph_ar
          tagline
          tagline_ar
          link {
            text_ar
            text
            url
          }
        }
      }
    }
    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    widget: allWidget {
      edges {
        node {
          description
          id
          image

          link_text
          link
          name
          title
          type
        }
      }
    }
    widget1: widget(name: { eq: "how-we-works" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description_ar
        title_ar
        description
        image

        link
        svg
        title
      }
    }
    widget2: widget(name: { eq: "property-type" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description_ar
        title_ar
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 500) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget3: widget(name: { eq: "explore-properties" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    widget4: widget(name: { eq: "our-latest-news" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    widget5: widget(name: { eq: "it-is-time-to-expect-more" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget6: widget(name: { eq: "our-latest-events" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
  }
`
export default IndexPage
